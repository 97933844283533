// material-ui
import { useTheme } from "@mui/material/styles";
import { Avatar, Box, useMediaQuery } from "@mui/material";

// project imports
import LAYOUT_CONST from "shared-ui/constant";
import LogoSection from "../LogoSection";

import SearchSection from "./SearchSection";
import MobileSection from "./MobileSection";
import ProfileSection from "./ProfileSection";
import LocalizationSection from "./LocalizationSection";
import NotificationSection from "./NotificationSection";
import ThemeModeLayout from "./Theme";

// assets
import { IconMenu2 } from "@tabler/icons";
import { uiStore } from "shared-ui/store";
import { observer } from "mobx-react";

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = () => {
  const theme = useTheme();
  const { drawerOpen, layout } = uiStore.config;
  const matchDownMd = useMediaQuery(theme.breakpoints.down("md"));

  const handleClick = () => {
    uiStore.updateConfig("drawerOpen", !drawerOpen);
  };

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignContent="center"
      alignItems="center"
      sx={{ width: "100%" ,height:'46px',
        background: theme.palette.background.default,
        

      }}
    >
      {/* logo & toggler button */}
      <Box
        sx={{
          width: 228,
          display: "flex",
          justifyContent: "space-between",
          [theme.breakpoints.down("md")]: {
            width: "auto",
          },
        }}
      >
        <Box
          component="span"
          sx={{ display: { xs: "none", md: "block" }, flexGrow: 1 }}
        >
          <LogoSection />
        </Box>

        {layout === LAYOUT_CONST.VERTICAL_LAYOUT ||
        (layout === LAYOUT_CONST.HORIZONTAL_LAYOUT && matchDownMd) ? (
          <Avatar
            variant="rounded"
            sx={{
              display: matchDownMd ?'block' :'none',
              ...theme.typography.commonAvatar,
              ...theme.typography.mediumAvatar,
              overflow: "hidden",
              transition: "all .2s ease-in-out",
              background:
                theme.palette.mode === "dark"
                  ? theme.palette.dark.main
                  : theme.palette.secondary.light,
              color:
                theme.palette.background.menu,
              "&:hover": {
                background:
                  theme.palette.mode === "dark"
                    ? theme.palette.secondary.main
                    : theme.palette.primary.main,
                color:
                  theme.palette.mode === "dark"
                    ? theme.palette.secondary.light
                    : theme.palette.primary.light,
              },
            }}
            color="inherit"
            onClick={handleClick}
          >
            <IconMenu2 stroke={1.5} size="20px"
            sx={{
              color: theme.palette.background.menu,
            }} />
          </Avatar>
        ) : null}
      </Box>
      {/* header search */}

      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        fullWidth
        sx={{ width: "fit-content", float: "left" }}
      >
        <SearchSection />

        {/* <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
            <LocalizationSection />
        </Box> */}

        {/* notification & profile */}
        <NotificationSection />

        {/* <ThemeModeLayout /> */}

        <ProfileSection />

        {/* mobile header */}
        <Box sx={{ display: { xs: "block", sm: "none" } }}>
          <MobileSection />
        </Box>
      </Box>
    </Box>
  );
};

export default observer(Header);
