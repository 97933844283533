import { useState, useEffect, useRef } from "react";
import {
  Button,
  Card,
  Divider,
  Grid,
  Icon,
  TextField,
  Typography,
  Tabs,
  Tab,
  CircularProgress,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  IconButton,
  TableHead,
  TableRow,
  MenuItem,
  Select,
} from "@mui/material";

import { useParams } from "react-router-dom";

import { v4 as uuidv4 } from "uuid";
import {
  FirstPage,
  LastPage,
  NavigateBefore,
  NavigateNext,
} from "@mui/icons-material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
// import { Box } from "@mui/system";
import React from "react";
// import { IconAdjustmentsHorizontal } from "@tabler/icons";

import { styled, useTheme } from "@mui/material/styles";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import BackupIcon from "@mui/icons-material/Backup";
import HistoryIcon from "@mui/icons-material/History";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";
import { IconAdjustmentsHorizontal } from "@tabler/icons";

// import DashboardNew from "./AiGenCard/DashboardNew.js"
import MultiFileUploader from "shared-ui/ui-component/Multifileuploader";
import SubCard from "shared-ui/ui-component/cards/SubCard";
import { gridSpacing } from "shared-ui/store/constant";

// import { v4 as uuidv4 } from "uuid";
import api from "service/api";
// import ChatInterface from "./ChatInterface";
import DataVisualization from "./AiGenCard/DataVisualization";
import InputPromptQuery from "./AiGenCard/InputPromptQuery";
import zIndex from "@mui/material/styles/zIndex";

import ModeCommentOutlinedIcon from "@mui/icons-material/ModeCommentOutlined";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import AutoAwesomeOutlinedIcon from "@mui/icons-material/AutoAwesomeOutlined";
import Tooltip from "@mui/material/Tooltip";
import { useLocation } from "react-router-dom";

const Dashboard = () => {
  const theme = useTheme();

  const { appId } = useParams(); // Access the app_id from the URL

  const location = useLocation();
  const { title, endpoint } = location.state || {}; // If state is undefined, destructure safely

  console.log(title, endpoint, "state passed");

  const [activeTab, setActiveTab] = useState(0);
  // const [loading, setLoading] = useState(false);

  const [toggle, setToggle] = useState(false);

  const [query, setQuery] = useState("");
  const [prompts, setPrompts] = useState([]);

  console.log(prompts, "iframedash");

  const [sessionId] = useState(uuidv4());

  console.log(sessionId, "sessionId heere");

  const [loading, setLoading] = useState(false);
  console.log(loading, "loading true");

  const [hide,setHide]= useState(false)

  const [scroll,setScroll] = useState(false)
  console.log(scroll,"scroll value");
  

  const [firstQuery, setFirstQuery] = useState(""); // For single input prompt

  // Handle changes for the input query
  const handleFirstQueryChange = (value) => {
    setFirstQuery(value);
  };

  // Handle submission of the query and generate new DataVisualization for each prompt
  const handleFirstQueryAsk = async () => {
    const newPrompt = {
      query: firstQuery,
      uuid: uuidv4(),
      tableData: [],
      sqlQuery: "",
      loading: true,
      page: 0,
      rowsPerPage: 10,
      totalRows: 0,
      isRendered: false,
      iframeKey: uuidv4(),
    };
    setLoading(true);

    try {
      const response = await api.fetchData(
        newPrompt.page + 1,
        newPrompt.rowsPerPage,
        newPrompt.query,
        sessionId,
        newPrompt.uuid,
        endpoint
      );

      // Handle the response and update the prompt accordingly
      newPrompt.loading = false; // Stop loading once response is received

      if (response && response.response) {
        newPrompt.tableData = response.response;
        newPrompt.sqlQuery = response.sql;
        newPrompt.totalRows = response.count || 0;
        newPrompt.isRendered = true;
        newPrompt.iframeKey = uuidv4();
      } else {
        newPrompt.tableData = [];
        newPrompt.totalRows = 0;
        newPrompt.isRendered = true;
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data:", error);
      newPrompt.error = "Failed to fetch data. Please try again..";
      setFirstQuery("");
      // setPrompts((prevPrompts) => [...prevPrompts, newPrompt]);
      setPrompts((prevPrompts) =>
        prevPrompts.map((prompt) => ({
          ...prompt,
          query: "", // Set query field to empty string
        }))
      );
    } finally {
      // newPrompt.loading = false;
      setPrompts((prevPrompts) => [...prevPrompts, newPrompt]);
      setLoading(false);
      setFirstQuery(""); // Clear the input after submission
      setHide(true)
    }
  };

  // Handle changes for dynamic query inputs
  const handleQueryChange = (index, value) => {
    const newPrompts = [...prompts];
    newPrompts[index].query = value;
    newPrompts[index].error = ""; // Reset error on new query
    setPrompts(newPrompts);
  };

  // Handle page changes in DataVisualization components
  const handleRowsPerPageChange = (promptIndex, event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    const updatedPrompts = [...prompts];
    updatedPrompts[promptIndex].rowsPerPage = newRowsPerPage;
    updatedPrompts[promptIndex].page = 0;
    setPrompts(updatedPrompts);
    // Trigger fetching data with new rows per page
    handleAskPaginated(promptIndex);
  };

  const setPage = (promptIndex, newPage) => {
    const updatedPrompts = [...prompts];
    updatedPrompts[promptIndex].page = newPage;
    setPrompts(updatedPrompts);
    handleAskPaginated(promptIndex);
  };

  // const handleAsk = async (index) => {
  //   const updatedPrompts = [...prompts];
  //   const currentPrompt = updatedPrompts[index];
  //   currentPrompt.loading = true;
  //   setLoading(true);

  //   console.log("handltask", updatedPrompts);
  //   setPrompts((prevPrompts) => {
  //     const updatedPrompts = [...prevPrompts];
  //     updatedPrompts[index] = { ...updatedPrompts[index], loading: true };
  //     return updatedPrompts;
  //   });

  //   try {
  //     const response = await api.fetchData(
  //       currentPrompt.page + 1,
  //       currentPrompt.rowsPerPage,
  //       currentPrompt.query,
  //       sessionId,
  //       currentPrompt.uuid
  //     );
  //     console.log("change uuid frst", currentPrompt.iframeKey);

  //     currentPrompt.loading = false; // Stop loading once response is received
  //     if (response && response.response) {
  //       currentPrompt.tableData = response.response;
  //       currentPrompt.sqlQuery = response.sql;
  //       currentPrompt.totalRows = response.count || 0;
  //       currentPrompt.isRendered = true;
  //       currentPrompt.iframeKey = uuidv4();
  //       console.log("change uuid", currentPrompt.iframeKey);
  //     } else {
  //       currentPrompt.tableData = [];
  //       currentPrompt.totalRows = 0;
  //       currentPrompt.isRendered = true;
  //     }
  //   } catch (error) {
  //     setLoading(false);
  //     currentPrompt.loading = false;
  //     console.error("Error fetching data:", error);
  //     currentPrompt.error = "Failed to fetch data. Please try again./";
  //     setPrompts((prevPrompts) =>
  //       prevPrompts.map((prompt) => ({
  //         ...prompt,
  //         query: "", // Set query field to empty string
  //       }))
  //     );
  //   } finally {
  //     currentPrompt.loading = false;
  //     setPrompts(updatedPrompts);
  //     setLoading(false);
  //     setFirstQuery("");
  //     // setPrompts((prevPrompts) =>
  //     //   prevPrompts.map(prompt => ({
  //     //     ...prompt,
  //     //     query: "" // Set query field to empty string
  //     //   }))
  //     // );
  //   }
  // };

  const handleAskPaginated = async (index) => {
    // Make a copy of the prompts array
    const updatedPrompts = [...prompts];
    const currentPrompt = updatedPrompts[index];

    // Set the loading state for the current prompt
    currentPrompt.loading = true;

    // Update the prompts array with the loading state
    setPrompts((prevPrompts) => {
      const updatedPrompts = [...prevPrompts];
      updatedPrompts[index] = { ...updatedPrompts[index], loading: true };
      return updatedPrompts;
    });

    try {
      // Fetch the data based on the current prompt's parameters
      const response = await api.fetchData(
        currentPrompt.page + 1,
        currentPrompt.rowsPerPage,
        currentPrompt.query,
        sessionId,
        currentPrompt.uuid,
        endpoint 
      );

      currentPrompt.loading = false; // Stop loading once the response is received

      if (response && response.response) {
        // Update the prompt with the fetched data and other response values
        currentPrompt.tableData = response.response;
        currentPrompt.sqlQuery = response.sql;
        currentPrompt.totalRows = response.count || 0;
        currentPrompt.isRendered = true;

        // Here, do NOT change the uuid unless you really need to
        if (!currentPrompt.iframeKey) {
          currentPrompt.iframeKey = uuidv4(); // Generate the uuid ONLY if it's missing
          console.log("Generated uuid:", currentPrompt.iframeKey);
        } else {
          console.log("Keep the same uuid:", currentPrompt.iframeKey);
        }
      } else {
        // Handle the case where there is no data in the response
        currentPrompt.tableData = [];
        currentPrompt.totalRows = 0;
        currentPrompt.isRendered = true;
      }
    } catch (error) {
      // Handle errors in the data fetch
      setLoading(false);
      currentPrompt.loading = false;

      setPrompts((prevPrompts) =>
        prevPrompts.map((prompt) => ({
          ...prompt,
          query: "", // Optionally reset the query on error
        }))
      );

      console.error("Error fetching data:", error);
      currentPrompt.error = "Failed to fetch data. Please try again.";
    } finally {
      // Update the prompts state to reflect the changes made to the current prompt
      currentPrompt.loading = false;
      setPrompts(updatedPrompts);
      setFirstQuery("");
    }
  };

  const getRowsPerPageOptions = (totalRows) => {
    if (totalRows <= 5) return [5];
    if (totalRows <= 10) return [5, 10];
    if (totalRows <= 50) return [5, 10, 20, 30, 40, 50];
    if (totalRows <= 100) return [5, 10, 20, 40, 60, 80, 100];
    return [5, 10, 25, 50, 100, 500];
  };

  const renderPagination = (promptIndex, page, rowsPerPage, totalRows) => {
    const totalPages = Math.ceil(totalRows / rowsPerPage);
    const rowsPerPageOptions = getRowsPerPageOptions(totalRows);

    if (totalRows > 0) {
      return (
        <Grid
          container
          spacing={gridSpacing}
          alignItems="center"
          justifyContent="space-between"
          display="flex"
          mt={1}
        >
          <Grid item xs={4}>
            <Typography variant="body1">Total Records: {totalRows}</Typography>
          </Grid>
          <Grid item xs={8}>
            <Grid
              container
              spacing={1}
              alignItems="center"
              justifyContent="end"
            >
              <Grid xs={3} item display="flex" alignItems="center">
                <Select
                  value={rowsPerPage}
                  onChange={(event) =>
                    handleRowsPerPageChange(promptIndex, event)
                  }
                  displayEmpty
                  variant="outlined"
                  size="small"
                >
                  {rowsPerPageOptions.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid
                xs={9}
                item
                display="flex"
                alignItems="center"
                justifyContent="end"
              >
                <IconButton
                  onClick={() => setPage(promptIndex, 0)}
                  disabled={page === 0}
                >
                  <FirstPage />
                </IconButton>
                <IconButton
                  onClick={() => setPage(promptIndex, page - 1)}
                  disabled={page === 0}
                >
                  <NavigateBefore />
                </IconButton>
                <Typography variant="body2">
                  {page + 1} of {totalPages}
                </Typography>
                <IconButton
                  onClick={() => setPage(promptIndex, page + 1)}
                  disabled={page >= totalPages - 1}
                >
                  <NavigateNext />
                </IconButton>
                <IconButton
                  onClick={() => setPage(promptIndex, totalPages - 1)}
                  disabled={page >= totalPages - 1}
                >
                  <LastPage />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      );
    }
    return null;
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleToggleChatBox = () => {
    setToggle(!toggle);
  };

  const handleAddPrompt = () => {
    setScroll(!scroll)
    const newPrompt = {
      query: "",
      uuid: uuidv4(),
      tableData: [],
      sqlQuery: "",
      loading: false,
      page: 0,
      rowsPerPage: 10,
      totalRows: 0,
      isRendered: false,
      iframeKey: uuidv4(),
      error: null,
    };
    setPrompts((prevPrompts) => [...prevPrompts, newPrompt]);
    

  };

  const scrollDivRef = useRef(null);

  // Handle submission for each individual query
  const handleQuerySubmit = async (index) => {
    const updatedPrompts = [...prompts];
    const currentPrompt = updatedPrompts[index];
    currentPrompt.loading = true;
    setPrompts(updatedPrompts);
    setLoading(true);

    try {
      const response = await api.fetchData(
        currentPrompt.page + 1,
        currentPrompt.rowsPerPage,
        currentPrompt.query,
        sessionId,
        currentPrompt.uuid,
        endpoint
      );

      if (response && response.response) {
        currentPrompt.tableData = response.response;
        currentPrompt.sqlQuery = response.sql;
        currentPrompt.totalRows = response.count || 0;
        currentPrompt.isRendered = true;
        currentPrompt.iframeKey = uuidv4();
      } else {
        currentPrompt.tableData = [];
        currentPrompt.totalRows = 0;
        currentPrompt.isRendered = true;
      }
    } catch (error) {
      currentPrompt.loading = false;
      currentPrompt.error = "Failed to fetch data. Please try again.";
    } finally {
      currentPrompt.loading = false;
      setPrompts(updatedPrompts);
      setLoading(false);

      scrollDown();
    }
  };

  const scrollDown = () => {
    window.scrollTo(0, document.body.scrollHeight); // Scrolls to the bottom of the page
    console.log("Scrolled down to:", window.scrollY); // Log the current vertical scroll position
  };

  useEffect(() => {
    // Scroll down the page to a specific position (e.g., bottom of the newly rendered content)
    scrollDown();
  }, [scroll]); // Dependency array to run effect when prompts change loading

  return (
    <>
      <Grid container spacing={gridSpacing}>
        {/* Button to add a new prompt */}
        {/* <Box>
          <Card className={toggle ? "none" : "chat2Bubble"}>
            <Tooltip title="Add new task">
              <AddOutlinedIcon
                onClick={handleAddPrompt}
                sx={{
                  fontSize: "2.73em",
                  fontWeight: "900",
                }}
              />
            </Tooltip>
          </Card>
        </Box> */}

        <Grid item xs={12} xl={toggle ? 9 : 12}>
          <Grid
            item
            xs={12}
            md={12}
            mb={gridSpacing}
            sx={{ overflowY: "auto", position:'sticky', top:'85px', zIndex:'999' }}
          >
            <Card
              sx={{
                background: theme.palette.background.default,
                border: "1px solid",
                borderColor: theme.palette.background.border,
              }}
             
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                p={2}
                py={2}
              >
                <Typography
                  ml={0.51}
                  variant="appHead"
                  color={theme.palette.primary.main}
                >
                  {title}
                </Typography>
                <Typography
                  display="flex"
                  variant="chatText"
                  alignItems="center"
                >
                  <IconAdjustmentsHorizontal stroke={1.5} size="20px" />
                  Filters {/* loadidng  {loading ? "true" : "flase"}  */}
                  <Box ml={1}>
                    <IconButton onClick={handleToggleChatBox}>
                      <AutoAwesomeOutlinedIcon
                        sx={{
                          color: theme.palette.primary.main,
                        }}
                      />
                    </IconButton>
                  </Box>
                </Typography>
              </Box>
            </Card>
          </Grid>

          <div ref={scrollDivRef}>
         
              <div
                style={{
                  textAlign: "center",
                  marginTop: "20px",
                  color: "gray",
                  display: hide ? 'none' :'block' 
                }}
              >
                <Card
                  key={prompt.uuid}
                  sx={{
                    mb: 4,
                    p: gridSpacing,
                    background: theme.palette.background.default,
                    border: "1px solid",
                    borderColor: theme.palette.background.border,
                    display:'flex'
                  }}
                >
                  <InputPromptQuery
                    prompt={{ query: firstQuery }}
                    handleQueryChange={(_, value) =>
                      handleFirstQueryChange(value)
                    }
                    handleAsk={handleFirstQueryAsk}
                    btnName={
                      loading ? (
                        <CircularProgress
                          sx={{
                            color: "#ffff",
                          }}
                          size={30}
                        />
                      ) : (
                        "Ask"
                      )
                    }
                    loading={loading}
                  />
                   <Box>
                      <Button
                        onClick={handleAddPrompt}
                      variant="primary"
                      sx={{
                        p: 1.1,
                        minHeight: "49px",
                        mx:1,
                        border: '1px solid',
                        borderColor: theme.palette.background.border,
                        borderColor:theme.palette.primary.main,
                        // color: theme.palette.secondary.main,
                        '&:hover':{
                          background:theme.palette.primary.main,
                          color:theme.palette.background.default
                        },
                      }}
                      >
                        <Tooltip title="Add new task">
                          <AddOutlinedIcon
                          
                            sx={{
                              fontSize: "2.3em",
                              fontWeight: "900",
                            }}
                          />
                        </Tooltip>
                      </Button>
                    </Box>
                   

                </Card>
                
              </div>
        
          

            {(
              prompts.map((prompt, index) => (
                <Card
                  key={prompt.uuid}
                  sx={{
                    mb: 2,
                    p: gridSpacing,
                    background: theme.palette.background.default,
                    border: "1px solid",
                    borderColor: theme.palette.background.border,
                  }}
                >
                  <DataVisualization
                    key={prompt.uuid}
                    prompt={prompt}
                    index={index}
                    renderPagination={renderPagination}
                  />

                  <Grid item xs={12}>
                    {/* Query input */}

                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                        gap: "10px",
                      }}
                    >
                      <TextField
                        fullWidth
                        label={`Query ${index + 1}`}
                        value={prompt.query}
                        onChange={(e) =>
                          handleQueryChange(index, e.target.value)
                        }
                        // error={!!prompt.error}
                        // helperText={prompt.error || ""}
                      />

                      {/* Submit button for each individual query */}
                      <Button

                        variant="contained"
                        onClick={() => handleQuerySubmit(index)}
                        disabled={prompt.loading || !prompt.query}
                        sx={{
                          p: 1,
                          minHeight: "49px",
                        }}
                      >
                        {prompt.loading ? (
                          <CircularProgress size={24} />
                        ) : (
                          "Ask"
                        )}
                      </Button>
                   
                      <Button
                      onClick={handleAddPrompt}
                        sx={{
                          p: 1,
                          minHeight: "49px",
                          border: '1px solid',
                          borderColor: theme.palette.background.border,
                          borderColor:theme.palette.primary.main,
                          // color: theme.palette.secondary.main,
                          '&:hover':{
                            background:theme.palette.primary.main,
                            color:theme.palette.background.default
                          },
                        }}>
                        <Tooltip title="Add new task">
                          <AddOutlinedIcon
                            
                            sx={{
                              fontSize: "2.3em",
                              fontWeight: "900",
                            }}
                          />
                        </Tooltip>
                      </Button>
                    </Box>

                  </Grid>

                  {/* <InputPromptQuery
                  prompt={prompt}
                  index={index}
                  handleQueryChange={handleQueryChange}
                  handleAsk={handleAsk}
                  btnName={
                    loading ? (
                      <CircularProgress
                        sx={{
                          color: "#ffff",
                        }}
                        size={30}
                      />
                    ) : (
                      "Analyse"
                    )
                  }
                  disabled={loading ? true : false}
                /> */}
                </Card>
              ))
            )}
          </div>
        </Grid>

        {/* chatbot space./////////// */}

        <Grid
          item
          xl={3}
          position="relative"
          sx={{
            display: toggle ? "block" : "none",
            right: toggle ? "0rem" : "150rem",
            transition: ".3s",
          }}
        >
          <Card
            sx={{
              background: theme.palette.background.default,
              border: "1px solid",
              borderColor: theme.palette.background.border,
              p: 2,
              minHeight: "80vh",
              width: "100%",
              maxHeight: "88vh",
              position: "sticky",
              top: "85px",
              overflowY: "scroll",
              "&::-webkit-scrollbar": {
                width: "2px",
              },
              "-ms-overflow-style": "none", // IE and Edge
              "scrollbar-width": "none", // Firefox
            }}
          >
            <Typography
              my={2}
              variant="appHead"
              display="flex"
              alignItems="center"
              gap={1}
            >
              <AutoAwesomeIcon
                sx={{
                  color: theme.palette.secondary.main,
                  mx: 1,
                }}
              />
              AI Chat Bot
            </Typography>

            <Divider
              sx={{
                background: theme.palette.grey[50],
              }}
            ></Divider>

            <Box className="chatBotHeader">
              <Tabs
                value={activeTab}
                onChange={handleTabChange}
                aria-label="Chat Bot Tabs"
                sx={{
                  background: theme.palette.background.default,
                }}
              >
                <Tab
                  label="AI Chat"
                  icon={<TravelExploreIcon sx={{ color: "primary.main" }} />}
                  iconPosition="start"
                />
                <Tab
                  label="Data File"
                  icon={<BackupIcon sx={{ color: "primary.main" }} />}
                  iconPosition="start"
                />
                <Tab
                  label="History"
                  icon={<HistoryIcon sx={{ color: "primary.main" }} />}
                  iconPosition="start"
                />
              </Tabs>
            </Box>

            {activeTab === 0 && <div className="chatBotBody"></div>}

            {activeTab === 1 && (
              <Box height="70vh">
                <Grid container alignItems="center" overflow="hidden">
                  <Grid item xs={12} p={2} pt="10vh">
                    <MultiFileUploader
                      allowMultiple={true}
                      appID={appId}
                      sessionID={sessionId}
                    />
                  </Grid>
                </Grid>
              </Box>
            )}

            {activeTab === 2 && (
              <div className="chatBotBody">
                <Box mt={5} gap={1}>
                  {/* <SubCard
                    sx={{
                      mb: 1,
                      background: theme.palette.background.default,
                    }}
                  >
                    <Typography variant="chatText">
                      No of employess work on Heat Exchange section 12
                    </Typography>
                  </SubCard>
                  <SubCard
                    sx={{
                      mb: 1,
                      background: theme.palette.background.default,
                    }}
                  >
                    <Typography variant="chatText">
                      No of employess worked on section 12
                    </Typography>
                  </SubCard>
                  <SubCard
                    sx={{
                      mb: 1,
                      background: theme.palette.background.default,
                    }}
                  >
                    <Typography variant="chatText">
                      No of employess work on Heat Exchange section 12 lorem
                      ipsum
                    </Typography>
                  </SubCard> */}
                </Box>
              </div>
            )}

            <div className="chatInput">
              <Grid
                item
                xs={12}
                sx={{
                  py: 2,
                  background: theme.palette.background.default,
                }}
              >
                <Card
                  sx={{
                    width: "100%",
                    background: theme.palette.background.default,
                  }}
                >
                  <Divider />
                  <Box
                    mt={2}
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    {/* <AudioToText/> */}
                    {/* <TextField placeholder="Ask me anything...." fullWidth />

                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleChatBtnClick}
                      sx={{
                        height: "49px",
                        ml: 1,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {loading ? (
                        <CircularProgress size={24} color="inherit" />
                      ) : (
                        <ArrowUpwardIcon />
                      )}
                    </Button> */}

                    <InputPromptQuery
                      prompt={{ query: firstQuery }}
                      handleQueryChange={(_, value) =>
                        handleFirstQueryChange(value)
                      }
                      handleAsk={handleFirstQueryAsk}
                      btnName={
                        loading ? (
                          <CircularProgress
                            sx={{
                              color: "#ffff",
                            }}
                            size={30}
                          />
                        ) : (
                          <ArrowUpwardIcon />
                        )
                      }
                    />
                  
                  </Box>
                </Card>
              </Grid>
            </div>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default Dashboard;
