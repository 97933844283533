import axios from "axios";

// Base URLs from environment variables
const apiUrl = `${process.env.REACT_APP_API_BASE_URL}`;
const appUIDashUrl = `${process.env.REACT_APP_UI_DASHBOARD_URL}`;
const baseURL = `${process.env.REACT_APP_API_BASE_URL || 'http://localhost:7002/chat'}`;

const fileUploadURL = `${process.env.REACT_APP_FILE_UPLOAD_URL}`;

// Create an Axios instance for API calls
const axiosInstance = axios.create({
  baseURL: baseURL,
});

// Create an Axios instance for dashboard calls
const dashAxiosInstance = axios.create({
  baseURL: appUIDashUrl,
});

const uploadAxiosInstance = axios.create({
  baseURL: fileUploadURL,
});


// Add a request interceptor for the API instance to include the token in headers
axiosInstance.interceptors.request.use(
  config => {
    const token = localStorage.getItem('serviceToken');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
      console.log(token, "service token");
    } else {
      console.log('Service token not found in localStorage');
    }
    return config;
  },
  error => Promise.reject(error)
);

// Add a request interceptor for the dashboard instance
dashAxiosInstance.interceptors.request.use(
  config => {
    const token = localStorage.getItem('serviceToken');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  error => Promise.reject(error)
);

// API service
const api = {
  fetchData: async (page, limit, query, sessionId, uuid, endpoint) => {
    try {
      const response = await axiosInstance.post(`${apiUrl}${endpoint}`, { query, sessionId, uuid }, {
        params: { page, limit },
      });
      return response.data;
    } catch (error) {
      throw new Error(error.message || "Request failed");
    }
  },

  fetchFiles: async (query, uuid) => {
    try {
      const response = await axiosInstance.post(`${apiUrl}run_files`, { query, uuid }, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      return response.data;
    } catch (error) {
      throw new Error(error.message || "Request failed");
    }
  },

  // uploadFile: async (file, appID, sessionID, authToken) => {
  //   try {
  //     const formData = new FormData();
  //     formData.append('file', file);
  //     const response = await axiosInstance.post(`${apiUrl}upload`, formData, {
  //       headers: {
  //         "Content-Type": "multipart/form-data",
  //       },
  //     });
  //     return response.data;
  //   } catch (error) {
  //     throw new Error(error.message || "File upload failed");
  //   }
  // },

  uploadFile: async (file, appID, sessionID) => {
    const token = localStorage.getItem('serviceToken');
    try {
      const formData = new FormData();
      formData.append('file', file);
  
      // Correctly format the URL without extra characters
      const uploadURL = `${fileUploadURL}${appID}/uploadfiles/`;
      console.log("Upload URL:", uploadURL);

  
      const response = await axiosInstance.post(uploadURL, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          "Accept": "application/json",             // Accept header
          "session-id": sessionID,                  // Session ID header
          "Authorization": `Bearer ${token}`, // Set the Bearer token
        },
      });
  
      return response.data;
    } catch (error) {
      throw new Error(error.message || "File upload failed");
    }
  },
  


  getData: async () => {
    try {
      const response = await dashAxiosInstance.get(``); // Use `/` for clarity
      return response.data;
    } catch (error) {
      throw new Error(error.message || "GET request failed");
    }
  },
};

export default api;
