import React, { useEffect, useState } from "react";
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Tabs,
  Tab,
  Box,
} from "@mui/material";
import DatasetOutlinedIcon from "@mui/icons-material/DatasetOutlined";
import TableChartIcon from "@mui/icons-material/TableChart"; // Import Table icon
import BarChartIcon from "@mui/icons-material/BarChart"; // Import Graph icon

import { styled } from "@mui/system";

import SubCard from "shared-ui/ui-component/cards/SubCard";
import { gridSpacing } from "shared-ui/store/constant";
import { useTheme } from "@mui/material/styles";

const DataVisualization = ({
  prompt,
  index,
  handleRowsPerPageChange,
  renderPagination,
}) => {
  const [activeTab, setActiveTab] = useState(0); // State to track the active tab
  const theme = useTheme();

  // Handle tab change
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  // Custom table cell styling
  const CustomCell = styled(TableCell)({
    padding: "5px 20px", // Custom padding
  });

  // Log for debugging
  useEffect(() => {
    console.log("DataVisualization Props:", prompt);
    console.log("Table Data Length:", prompt.tableData.length);
  }, [prompt]);

  if (prompt.error) {
    return (
      <Typography color="error" variant="body1">
        {prompt.error}
      </Typography>
    );
  }

  if (!prompt.isRendered) return null;

  return (
    <Grid container spacing={gridSpacing} key={prompt.uuid} mb={3}>
      <Grid item xs={12}>
      <Tabs
        value={activeTab}
        onChange={handleTabChange}
        indicatorColor="primary"
        textColor="primary"
        
        sx={{
          height:'40px'
          
        }}
      >
        <Tab
          icon={<TableChartIcon />}
          label="Data"
          sx={{
            padding:0,
            flexDirection: 'row', // Make icon and label on one line
            gap: '5px', // Add some space between the icon and label
            alignItems: 'center', // Align vertically center
          }}
        />
        <Tab
          icon={<BarChartIcon />}
          label="Charts"
          sx={{
            padding:0,
            flexDirection: 'row', // Make icon and label on one line
            gap: '5px', // Add some space between the icon and label
            alignItems: 'center', // Align vertically center
          }}
        />
      </Tabs>
      

        <Box mt={2}>
          {/* Table Tab Content */}
          {activeTab === 0 ? (
            <SubCard>
              <TableContainer
                component={Paper}
                style={{
                  maxHeight: "500px",
                  height: "500px",
                }}
              >
                <Table stickyHeader>
                  {prompt.tableData && prompt.tableData.length > 0 ? (
                    <>
                      <TableHead>
                        <TableRow>
                          <CustomCell>S.No</CustomCell>
                          {Object.keys(prompt.tableData[0]).map((header) => (
                            <CustomCell key={header}>{header}</CustomCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody
                        sx={{
                          background: theme.palette.background.default,
                          border: "1px solid",
                          borderColor: theme.palette.background.border,
                        }}
                      >
                        {prompt.tableData.map((row, idx) => (
                          <TableRow key={idx}>
                            <CustomCell>{idx + 1}</CustomCell>
                            {Object.values(row).map((value, index) => (
                              <CustomCell key={index}>{value}</CustomCell>
                            ))}
                          </TableRow>
                        ))}
                      </TableBody>
                    </>
                  ) : (
                    <TableBody
                      sx={{
                        background: theme.palette.background.default,
                        border: "1px solid",
                        borderColor: theme.palette.background.border,
                      }}
                    >
                      <TableRow>
                        <TableCell
                          colSpan={
                            Object.keys(prompt.tableData[0] || {}).length + 1
                          }
                        >
                          <Typography variant="body1" align="center">
                            No data found for this graph ID...
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>

              {/* Pagination */}
              {prompt.tableData.length > 0 &&
                renderPagination(
                  index,
                  prompt.page,
                  prompt.rowsPerPage,
                  prompt.totalRows
                )}
            </SubCard>
          ):
          (
            <>
            {/* Iframe Tab Content */}
            <SubCard sx={{
             //  display: activeTab === 1 ? "block" : "none", 
           }}>
             {prompt.iframeKey ? (
               <iframe
                 key={prompt.iframeKey}
                 src={`${process.env.REACT_APP_DASHBOARD_BASE_URL}/${prompt.uuid}`}
                 style={{
                   width: "100%",
                   height: "600px",
                   border: "none",
                   // display: activeTab === 1 ? "block" : "none", // Only show iframe on the "Graph" tab
                 }}
                 title={`Graph-${prompt.uuid}`}
               />
             ) : (
               <Paper
                 style={{
                   width: "100%",
                   height: "600px",
                   display: activeTab === 1 ? "flex" : "none",
                   alignItems: "center",
                   justifyContent: "center",
                 }}
               >
                 <Typography variant="body1" align="center">
                   No graph available for this query
                 </Typography>
               </Paper>
             )}
           </SubCard>
           </>
          )}

      
        </Box>
      </Grid>
    </Grid>
  );
};

export default DataVisualization;
