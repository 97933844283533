// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Box,
  Avatar,
  Drawer,
  Stack,
  useMediaQuery,
  Typography,
} from "@mui/material";

// third-party
import PerfectScrollbar from "react-perfect-scrollbar";

// project imports
import MenuList from "../MenuList";
import LogoSection from "../LogoSection";
import MiniDrawerStyled from "./MiniDrawerStyled";
import Chip from "shared-ui/ui-component/extended/Chip";

import LAYOUT_CONST from "shared-ui/constant";
import { drawerWidth } from "shared-ui/store/constant";

import { observer } from "mobx-react";
import { uiStore } from "shared-ui/store";

import { IconMenu2 } from "@tabler/icons";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import CloseIcon from '@mui/icons-material/Close';
import { margin } from "@mui/system";

// ==============================|| SIDEBAR DRAWER ||============================== //

const Sidebar = () => {
  const theme = useTheme();
  const matchUpMd = useMediaQuery(theme.breakpoints.up("md"));
  const matchDownMd = useMediaQuery(theme.breakpoints.down("md"));

  const { drawerOpen, layout, drawerType } = uiStore.config;

  const logo = (
    <Box
      sx={{ display: "flex", mb: drawerOpen ? "1.75rem" : "4rem", mx: "auto" }}
    >
      <LogoSection />
    </Box>
  );
  const handleClick = () => {
    uiStore.updateConfig("drawerOpen", !drawerOpen);
  };

  const drawerContent = (
    <>
      <MenuList />

      {/* {layout === LAYOUT_CONST.VERTICAL_LAYOUT && drawerOpen} */}
      {/* {layout === LAYOUT_CONST.VERTICAL_LAYOUT && drawerOpen && (
                <Stack direction="row" justifyContent="center" sx={{ mb: 2 }}>
                    <Chip label={process.env.REACT_APP_VERSION} disabled chipcolor="secondary" size="small" sx={{ cursor: 'pointer' }} />
                </Stack>
            )} */}
    </>
  );

  const drawerSX = {
    // paddingLeft: drawerOpen ? '16px' : 0,
    // paddingRight: drawerOpen ? '16px' : 0,
    // paddingTop: drawerOpen ? '1px' : '1px',
    // marginTop: drawerOpen ? '-15px' : '0px',
    borderColor: theme.palette.background.border,
    paddingLeft: drawerOpen ? ".751rem" : "0",
    paddingRight: drawerOpen ? ".751rem" : "0",
  };

  const drawer = (
    <>
      {matchDownMd ? (
        <PerfectScrollbar>
          <Box sx={drawerSX}>{drawerContent}</Box>
        </PerfectScrollbar>
      ) : (
        <PerfectScrollbar
          component="div"
          style={{
            background: theme.palette.background.default,
            border: ".6px solid",
            borderLeft: 0,
            borderBottom: 0,
            borderTop: 0,
            height: !matchUpMd ? "calc(100vh - 56px)" : "calc(100vh - 88px)",
            ...drawerSX,
          }}
        >
          {drawerContent}
        </PerfectScrollbar>
      )}
    </>
  );

  return (
    <Box
      component="nav"
      sx={{ flexShrink: { md: 0 }, width: matchUpMd ? drawerWidth : "auto" }}
      aria-label="mailbox folders"
    >
      {matchDownMd ||
      (drawerType === LAYOUT_CONST.MINI_DRAWER && drawerOpen) ? (
        <Drawer
          variant={matchUpMd ? "persistent" : "temporary"}
          anchor="left"
          open={drawerOpen}
          onClose={() => uiStore.updateConfig("drawerOpen", !drawerOpen)}
          sx={{
            "& .MuiDrawer-paper": {
              mt: matchDownMd ? 0 : 11,
              zIndex: 1099,
              width: drawerWidth,
              background: theme.palette.background.default,
              color: theme.palette.text.secondary,
            },
          }}
          ModalProps={{ keepMounted: true }}
          color="inherit"
        >
          {matchDownMd && logo}
          {drawer}
        </Drawer>
      ) : (
        <MiniDrawerStyled variant="permanent" open={drawerOpen}>
          {/* {logo} */}

          <Box
            py={2}
            mt={10}
            sx={{
              border: 0,
              borderRight: "1px solid",
              borderColor: theme.palette.background.border,
              width:'100%'
            }}
            
          >
            {layout === LAYOUT_CONST.VERTICAL_LAYOUT ||
            (layout === LAYOUT_CONST.HORIZONTAL_LAYOUT && matchDownMd) ? (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                ml={2.5}
                mb={1}
                
              >
                <Avatar
                  variant="rounded"
                  sx={{
                    ...theme.typography.commonAvatar,
                    ...theme.typography.mediumAvatar,
                    display: drawerOpen ? 'none': 'block',
                    overflow: "hidden",
                    transition: "all .2s ease-in-out",
                    background:
                      theme.palette.mode === "dark"
                        ? theme.palette.dark[900]
                        : theme.palette.background.paper,
                    color:
                      theme.palette.text.dark,
                    // border: "1px solid",
                    // borderColor: theme.palette.background.border,
                    "&:hover": {
                      borderColor:
                        theme.palette.mode === "dark"
                          ? theme.palette.secondary.main
                          : theme.palette.secondary.dark,
                      // color: theme.palette.mode === 'dark' ? theme.palette.secondary.light : theme.palette.secondary.light
                    },
                    width: "43px",
                    height: "43px",
                  }}
                  color="inherit"
                  onClick={handleClick}
                >
                  <IconMenu2 stroke={2.2} size="27px" />
                </Avatar>
                {drawerOpen ? (
                  <Box mx={2} mt={1} display="flex" justifyContent="space-between" alignItems="center" width="100%">
                  <Typography variant="body3"> MENU </Typography>
                  
                    <CloseIcon
                      onClick={handleClick}
                      size={20}
                      sx={{ fontSize: "1.53em", cursor: "pointer", mr:1 }}
                    />
                  </Box>
                ) : (
                  ""
                )}
              </Box>
            ) : null}
          </Box>
          {drawer}
        </MiniDrawerStyled>
      )}
    </Box>
  );
};

export default observer(Sidebar);
