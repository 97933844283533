// InputPromptQuery.js
import React, { useState } from "react";
import { TextField, Box, Button } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';

function InputPromptQuery({ prompt, index, handleQueryChange, handleAsk,btnName }) {

  console.log(prompt.loading, '[ront input');

  const [loaderBtn , setloaderBtn] = useState(false)
  
  return (
    <Box sx={{ display:'flex', alignItems:'center', width:'100%', gap:'10px'}}>
      <TextField
        fullWidth
        value={prompt.query}
        label="Enter your query"
        onChange={(e) => handleQueryChange(index, e.target.value)}
        variant="outlined"
        // error={prompt.error}
        // sx={{
        //   "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
        //     borderColor: "inherit", // Override the default red border
        //   },
        //   "& .MuiFormLabel-root.Mui-error": {
        //     color: "blue", // Override the default red label color
        //   },
        // }}

        
      />
      <Box>
        <Button
          variant="contained"
          onClick={() => handleAsk(index)}
          // disabled={prompt.loading || !prompt.query}
          sx={{
            p:1,
            minHeight:'49px'
          }}
        >
          { btnName }
        </Button>
      </Box>
    </Box>
  );
}

export default InputPromptQuery;
